<template>
    <ModalDialogContainer ref="modalContainer" v-model:display="displayStatus"  @keydown="escapeToClose">
        <div class="modal-dialog modal-md" role="document" @click="stopPropagationEvent">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">{{ $t("Select profile picture") }}</div>
                    <button type="button" class="btn-close" :title="$t('Close')" @click="close"></button>
                </div>
                <div class="modal-body">
                    <Cropper 
                        ref="cropper" 
                        :src="image"
                        @error="error"
                        :stencil-props="{
                            aspectRatio: 1,
                            previewClass: 'preview-custom'
                        }"
                    >
                    </Cropper>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="confirm">
                        <i class="fas fa-check"></i> {{ $t("Confirm") }}
                    </button>
                    <button type="button" class="btn btn-cancel" @click="close">
                        {{ $t("Cancel") }}
                    </button>
                </div>
            </div>
        </div>
    </ModalDialogContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "@/utils/v-model";

import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default defineComponent({
    components: {
        Cropper,
    },
    name: "ImageSelectorModal",
    emits: ["update:display", "confirm"],
    props: {
        display: Boolean,
        file: File,
    },
    setup(props) {
        return {
            displayStatus: useVModel(props, "display"),
        };
    },
    data: function () {
        return {
            image: "",
        };
    },
    methods: {
        close: function () {
            this.displayStatus = false;
        },

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.displayStatus = false;
            }
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        error: function() {
			this.$showMessageModal(this.$t("Error"), this.$t("Invalid image"));
            this.close();
		},

        dataURLtoFile: function(dataurl: string, filename: string) {
            const arr = dataurl.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },

        confirm: function () {
            const { canvas } = this.$refs.cropper.getResult();

            const resultImage = canvas.toDataURL();

            const file = this.dataURLtoFile(resultImage, "image.png");

            this.$emit("confirm", file);
            this.close();
        },
    },
    mounted: function () {
        this.image = URL.createObjectURL(this.file);

        if (this.display) {
            this.$autoFocus();
        }
    },
    watch: {
        display: function () {
            if (this.display) {
                this.$autoFocus();
            }
        },
    },
});
</script>

<style>
.preview-custom {
    border-radius: 50% !important;
}
</style>
