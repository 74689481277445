<template>
    <ModalDialogContainer class="d-flex justify-content-center" ref="modalContainer" v-model:display="displayStatus" @keydown="escapeToClose">
        <div class="modal-dialog modal-lg cookies-container" role="document " @click="stopPropagationEvent">
            <div class="modal-content w-100 h-100">
                <div class="modal-header">
                    <div class="modal-title">{{ $t("Cookies preferences") }}</div>
                    <button type="button" class="btn-close" :title="$t('Close')" @click="close"></button>
                </div>
                <div class="modal-body d-flex p-0 pe-3">
                    <div class="side-menu col-2 col-lg-3">
                        <button class="side-bar-button btn d-flex justify-center gap-2 p-3 w-100" :class="['side-bar-option', { 'selected': selectedDiv === 'essential' }]" @click.prevent="showDiv('essential')">
                            <div class="side-bar-option-icon"><i class="fa-solid fa-plus"></i></div>
                            <div class="side-bar-option-text">{{ $t("Essential") }}</div>
                        </button>

                        <button class="side-bar-button btn d-flex justify-center gap-2 p-3 w-100" :class="['side-bar-option', { 'selected': selectedDiv === 'all-cookies' }]" @click.prevent="showDiv('all-cookies')">
                            <div class="side-bar-option-icon"><i class="fa-solid fa-clone"></i></div>
                            <div class="side-bar-option-text">{{ $t("All") }}</div>
                        </button>
                    </div>
                    <div class="room-content col-10 col-lg-9 p-5">
                        <div v-if="visibleDiv === 'essential'">
                            <div class="cookie-option-desc">
                            {{ $t("We will only use the cookies required for this website to function") }}:
                            <ul class="list-cookie-purposes">
                                <li>{{ $t("For authentication purposes (in order to keep you logged in)") }}</li>
                                <li>{{ $t("In order to prevent spam or abuse of our services") }}</li>
                            </ul>
                        </div>
                        </div>

                        <div v-if="visibleDiv === 'all-cookies'">
                            <div class="cookie-option-desc">
                            {{ $t("We will also use cookies for the following purposes") }}:
                            <ul class="list-cookie-purposes">
                                <li>{{ $t("Improving our services by collecting usage data") }}</li>
                                <li>{{ $t("Customizing the experience based on your preferences") }}</li>
                                <li>{{ $t("Showing you advertisements based on your preferences") }}</li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button @click="acceptAllCookies" type="button" class="btn btn-primary accept-button" v-if="selectedDiv === 'all-cookies'">
                                <i class="fas fa-check"></i> {{ $t("Accept all cookies") }}
                            </button>
                            <button v-else @click="acceptOnlyEssentialCookies" type="button" class="btn btn-primary accept-button">
                                <i class="fas fa-check"></i> {{ $t("Accept only essential cookies") }}
                            </button>
                </div>
            </div>
        </div>
    </ModalDialogContainer>

</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { ColorThemeName, AppPreferences } from "@/control/app-preferences";

import { FocusTrap } from "@/utils/focus-trap";
import { useVModel } from "@/utils/v-model";

export default defineComponent({
    name: "CookiesModal",
    props: {
        display: Boolean,
    },
    setup(props) {
        const visibleDiv = ref("");
        return {
            focusTrap: null as FocusTrap,
            displayStatus: useVModel(props, "display"),
            visibleDiv,
        };
    },
    data: function () {
        return {
            theme: AppPreferences.Theme,
            displayLanguageModal: false,
            selectedDiv: 'essential',
        };
    },
    methods: {
        close: function () {
            this.displayStatus = false;
        },

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.displayStatus = false;
            }
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        focusLost: function () {
            if (this.display) {
                this.$el.focus();
            }
        },

        invertTheme: function () {
            AppPreferences.SetTheme(this.theme === "dark" ? "light" : "dark");
        },

        onThemeChanged: function (t: ColorThemeName) {
            this.theme = t;
        },

        selectLanguage: function () {
            this.displayLanguageModal = true;
        },

        acceptAllCookies: function () {
            AppPreferences.SetCookiesPreference("all");
            this.close();
        },

        acceptOnlyEssentialCookies: function () {
            AppPreferences.SetCookiesPreference("essential");
            this.close();
        },
        showDiv: function (divName) {
            this.visibleDiv = divName;
            this.selectedDiv = divName;
        },
    },
    mounted: function () {
        this.$listenOnAppEvent("theme-changed", this.onThemeChanged.bind(this));

        this.focusTrap = new FocusTrap(this.$el, this.focusLost.bind(this));

        if (this.display) {
            this.focusTrap.activate();
        }

        this.$autoFocus();

        this.showDiv('all-cookies');
    },
    beforeUnmount: function () {
        if (this.focusTrap) {
            this.focusTrap.destroy();
        }
    },
    watch: {
        display: function () {
            if (this.display) {
                if (this.focusTrap) {
                    this.focusTrap.activate();
                }
            } else {
                if (this.focusTrap) {
                    this.focusTrap.deactivate();
                }
            }
        },
    },
});
</script>

<style scoped>

.side-menu{
    border-right: 1px solid #e0e0e0;
}

.side-bar-button {
    cursor: pointer;
}

.dark-theme .side-bar-button {
    color: white;
}

.cookies-container{
    height: 370px;
}

.side-bar-button:active, .side-bar-button:focus, .side-bar-button:hover {
    background-color: var(--red-maya-dark);
    border-radius: 0;
    color: white !important;
}
.side-bar-button.selected {
    background-color: var(--red-maya);
    border-radius: 0;
    color: white;
}

.accept-button{
    border-radius: 0;
    background-color: var(--red-maya);
    color: white;
    border: none;
    width: 100%;
    border-bottom-left-radius:0.5rem !important;
    border-bottom-right-radius:0.5rem !important;
}

</style>
