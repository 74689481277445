// Global events manager

import { ColorThemeName } from "./app-preferences";

type CallbackFunctionVariadic = (...args: any[]) => void;

/**
 * Mapping of App events
 */
export type AppEventsMap = {
    /**
     * Event sent when AuthController changes its status
     */
    "auth-status-changed": () => void;

    /**
     * Event sent when AuthController changes its loading status
     * @param loading True if the auth status is being loaded
     */
    "auth-status-loading": (loading: boolean) => void;

    /**
     * Event sent when an Unauthorized (401) status code is received
     * This may indicate the session has expired
     */
    unauthorized: () => void;

    /**
     * Event sent when a locale is selected
     * @param locale The locale identifier
     */
    "set-locale": (locale: string) => void;

    /**
     * Event sent when a locale is loaded
     * @param locale The locale identifier
     */
    "loaded-locale": (locale: string) => void;

    /**
     * Event send when the router loading status changes
     * @param loading True if loading
     */
    "router-load-state-change": (loading: boolean) => void;

    /**
     * Event sent when the theme changes
     * @param theme The theme name
     */
    "theme-changed": (theme: ColorThemeName) => void;

    /**
     * Event sent to show a snackbar
     * @param message The message
     * @param position The position
     */
    snack: (message: string, position?: "left" | "center" | "right") => void;

    /**
     * Event sent to show a message modal
     * @param title The title
     * @param message The message
     */
    "msg-modal": (title: string, message: string) => void;

    /**
     * Event sent to show a message modal whit icon
     * @param title The title
     * @param message The message
     * @param icon The icon
     */
    "msg-modal-icon": (title: string, message: string, icon: string) => void;

    /**
     * Event sent to ask for user confirmation
     * @param options The options
     */
    "ask-confirmation": (options: { title: string; message: string; danger?: boolean; callback: () => void }) => void;

    /**
     * Event sent to show an image selector modal
     * @param options The options
     */
    "image-selector-modal": (options: { file: File; callback: (file: File) => void }) => void;

    /**
     * Event sent to ask for lave room
     * @param options The options
     */
    "ask-leave-room": (callback: () => void) => void;

    /**
     * Event sent to request a private call code
     * @param options The options
     */
    "request-call-code": (options: { readonly: boolean, code: string, callback: (code: string) => void }) => void;

    /**
     * Event sent to to show a modal to edit streaming video title
     * @param options The options
     */
    "show-update-streaming-video": (options: { title: string; callback: (title: string, resolve: any, reject: any) => void }) => void;

    /**
     * Show profile modal
     * @param uid User ID
     */
    "show-profile": (uid: string) => void;

    /**
     * Show streaming modal
     * @param sid Section ID
     * @param room Room ID
     * @param title Streaming title
     */
    "show-streaming-modal": (sid: string, room: string, title: string) => void;

    /**
     * Activity ack
     */
    "activity-ack": () => void;

    /**
     * Hide add policy modal
     */
    "hide-add-policy": () => void;

    /**
     * Hide edit policy modal
     */
    "hide-edit-policy": () => void;

    /**
     * Hide edit policy modal
     */
    "hide-delete-policy": () => void;

    /**
     * Hide edit policy modal
     */
    "hide-add-rule": () => void;

    /**
     * Hide edit policy modal
     */
    "hide-edit-rule": () => void;

    /**
     * Hide edit policy modal
     */
    "hide-delete-rule": () => void;

    /**
     * Hide edit policy modal
     */
    "hide-asign-user": () => void;

    /**
     * Load section
     */
    "load-section": () => void;

    /**
     * Load section pictures
     */
    "load-section-pictures": () => void;
};

export class AppEvents {
    public static events: {[key: string]: CallbackFunctionVariadic[]} = {};

    public static AddEventListener(eventName: string, handler: CallbackFunctionVariadic) {
        if (!AppEvents.events[eventName]) {
            AppEvents.events[eventName] = [];
        }
        AppEvents.events[eventName].push(handler);
    }

    public static Emit(eventName: string, ...args: any[]) {
        if (AppEvents.events[eventName]) {
            for (const handler of AppEvents.events[eventName]) {
                try {
                    handler(...args);
                } catch (ex) {
                    console.error(ex);
                }
            }
        }
    }

    public static RemoveEventListener(eventName: string, handler: CallbackFunctionVariadic) {
        if (!AppEvents.events[eventName]) {
            return;
        }
        const i = AppEvents.events[eventName].indexOf(handler);
        if (i >= 0) {
            AppEvents.events[eventName].splice(i, 1);
            if (AppEvents.events[eventName].length === 0) {
                delete AppEvents.events[eventName];
            }
        }
    }

    /**
     * Displays snackbar
     * @param message The message
     */
    public static ShowSnackBar(message: string) {
        AppEvents.Emit("snack", message);
    }

    /**
     * Displays message modal
     * @param title Modal title
     * @param message Modal message
     */
    public static ShowMessageModal(title: string, message: string) {
        AppEvents.Emit("msg-modal", title, message);
    }
    /**
     * Displays message modal
     * @param title Modal title
     * @param message Modal message
     * @param icon  Modal icon
     */
    public static ShowMessageModalIcon(title: string, message: string, icon: string) {
        AppEvents.Emit("msg-modal-icon", title, message, icon);
    }
}
