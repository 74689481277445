// API bindings: calls (Auto generated)

"use strict";

import { RequestErrorHandler, RequestParams, CommonAuthenticatedErrorHandler, CommonErrorHandler } from "@asanrom/request-browser";
import { getApiUrl, generateURIQuery } from "./utils";
import { CallInfo, CallConfiguration, UserTargetPrivateSupport, CallVideoList, RecordingStartResponse, RecordCallRequest, CallVideo, StreamingLiveResponse, CallStatsTopItem, StreamingStatsItem, RecordingStatusResponse, PrivateCallList, PrivateCallInfo, summarizeTranscriptionsRequest, UserLeaveCallTimeResponse } from "./definitions";

export class ApiCalls {
    /**
     * Method: GET
     * Path: /room/{roomId}/call
     * Get call information
     * @param roomId Room ID
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidCall(roomId: string, headerParams: GetRoomRoomidCallHeaderParameters): RequestParams<CallInfo, GetRoomRoomidCallErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call`),
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/call/config
     * Get call configuration
     * @param roomId Room ID
     * @returns The request parameters
     */
    public static GetRoomRoomidCallConfig(roomId: string): RequestParams<CallConfiguration, GetRoomRoomidCallConfigErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/config`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/call/config
     * Set call configuration
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidCallConfig(roomId: string, body: CallConfiguration): RequestParams<void, PostRoomRoomidCallConfigErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/config`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/call/mute
     * Mutes user sound (MOD)
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidCallMute(roomId: string, body: UserTargetPrivateSupport): RequestParams<void, PostRoomRoomidCallMuteErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/mute`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/call/unmute
     * Unmute user sound (MOD)
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidCallUnmute(roomId: string, body: UserTargetPrivateSupport): RequestParams<void, PostRoomRoomidCallUnmuteErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/unmute`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/call/turn
     * Gives turn
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidCallTurn(roomId: string, body: UserTargetPrivateSupport): RequestParams<void, PostRoomRoomidCallTurnErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/turn`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/call/kick
     * Kicks user from call (MOD)
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidCallKick(roomId: string, body: UserTargetPrivateSupport): RequestParams<void, PostRoomRoomidCallKickErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/kick`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/call/videos
     * Get list of call videos (recordings of calls)
     * It will return a max amount of items with the information to get the next page
     * @param roomId Room ID
     * @param queryParams Query parameters
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidCallVideos(roomId: string, queryParams: GetRoomRoomidCallVideosQueryParameters, headerParams: GetRoomRoomidCallVideosHeaderParameters): RequestParams<CallVideoList, GetRoomRoomidCallVideosErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/videos` + generateURIQuery(queryParams)),
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/call/videos
     * Start a new call recording
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidCallVideos(roomId: string, body: RecordCallRequest): RequestParams<RecordingStartResponse, PostRoomRoomidCallVideosErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/videos`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/call/videos/{videoId}
     * Get call video
     * @param roomId Room ID
     * @param videoId Video ID
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidCallVideosVideoid(roomId: string, videoId: string, headerParams: GetRoomRoomidCallVideosVideoidHeaderParameters): RequestParams<CallVideo, GetRoomRoomidCallVideosVideoidErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/videos/${encodeURIComponent(videoId)}`),
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/call/videos/{videoId}
     * Changes video title and description
     * @param roomId Room ID
     * @param videoId Video ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidCallVideosVideoid(roomId: string, videoId: string, body: RecordCallRequest): RequestParams<void, PostRoomRoomidCallVideosVideoidErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/videos/${encodeURIComponent(videoId)}`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /room/{roomId}/call/videos/{videoId}
     * Deletes call video (ADMIN)
     * @param roomId Room ID
     * @param videoId Video ID
     * @returns The request parameters
     */
    public static DeleteRoomRoomidCallVideosVideoid(roomId: string, videoId: string): RequestParams<void, DeleteRoomRoomidCallVideosVideoidErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/videos/${encodeURIComponent(videoId)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/call/videos/{videoId}/request_encode
     * Request video to be encoded to MP4 (for site admins)
     * @param roomId Room ID
     * @param videoId Video ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidCallVideosVideoidRequestencode(roomId: string, videoId: string, body: RecordCallRequest): RequestParams<void, PostRoomRoomidCallVideosVideoidRequestencodeErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/videos/${encodeURIComponent(videoId)}/request_encode`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/call/videos/{videoId}/{resolution}
     * Get call video playlist
     * @param roomId Room ID
     * @param videoId Video ID
     * @param resolution Resolution name
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidCallVideosVideoidResolution(roomId: string, videoId: string, resolution: string, headerParams: GetRoomRoomidCallVideosVideoidResolutionHeaderParameters): RequestParams<StreamingLiveResponse, GetRoomRoomidCallVideosVideoidResolutionErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/videos/${encodeURIComponent(videoId)}/${encodeURIComponent(resolution)}`),
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/call/stats/top
     * Stats / Get top users activity in a 24 hour period
     * @param roomId Room ID
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidCallStatsTop(roomId: string, queryParams: GetRoomRoomidCallStatsTopQueryParameters): RequestParams<CallStatsTopItem[], GetRoomRoomidCallStatsTopErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/stats/top` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/call/stats/user/{userId}
     * Stats / Get user activity stats (time in call)
     * @param roomId Room ID
     * @param userId User ID
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidCallStatsUserUserid(roomId: string, userId: string, queryParams: GetRoomRoomidCallStatsUserUseridQueryParameters): RequestParams<StreamingStatsItem[], GetRoomRoomidCallStatsUserUseridErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/stats/user/${encodeURIComponent(userId)}` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/call/recording/status
     * Get room recording status
     * @param roomId Room ID
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidCallRecordingStatus(roomId: string, headerParams: GetRoomRoomidCallRecordingStatusHeaderParameters): RequestParams<RecordingStatusResponse, GetRoomRoomidCallRecordingStatusErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/recording/status`),
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/call/private
     * Get list of the private calls in the room (ADMIN)
     * It will return a max amount of items with the information to get the next page
     * @param roomId Room ID
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidCallPrivate(roomId: string, queryParams: GetRoomRoomidCallPrivateQueryParameters): RequestParams<PrivateCallList, GetRoomRoomidCallPrivateErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/private` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/call/private/{callId}
     * Get private call information
     * @param roomId Room ID
     * @param callId Call ID
     * @returns The request parameters
     */
    public static GetRoomRoomidCallPrivateCallid(roomId: string, callId: string): RequestParams<PrivateCallInfo, GetRoomRoomidCallPrivateCallidErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/private/${encodeURIComponent(callId)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /room/{roomId}/call/private/{callId}
     * Deletes private call (ADMIN)
     * @param roomId Room ID
     * @param callId Call ID
     * @returns The request parameters
     */
    public static DeleteRoomRoomidCallPrivateCallid(roomId: string, callId: string): RequestParams<void, DeleteRoomRoomidCallPrivateCallidErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/private/${encodeURIComponent(callId)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/call/audio
     * Upload audio buffer to the call recording
     * @param roomId Room ID
     * @param formParams FromData parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidCallAudio(roomId: string, formParams: PostRoomRoomidCallAudioFormParameters): RequestParams<void, PostRoomRoomidCallAudioErrorHandler> {
        const formData = new FormData();

        formParams.file !== undefined && formData.append("file", formParams.file);
        formParams.date !== undefined && formData.append("date", formParams.date);
        formParams.uid !== undefined && formData.append("uid", formParams.uid);

        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/audio`),
            form: formData,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(400, "*", handler.badRequest)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/call/audio/summary
     * Summarize transcriptions
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidCallAudioSummary(roomId: string, body: summarizeTranscriptionsRequest): RequestParams<void, PostRoomRoomidCallAudioSummaryErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/audio/summary`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/call/leave-time
     * Get the time the user left the call
     * @param roomId Room ID
     * @returns The request parameters
     */
    public static GetRoomRoomidCallLeavetime(roomId: string): RequestParams<UserLeaveCallTimeResponse, GetRoomRoomidCallLeavetimeErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/leave-time`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/call/blob
     * Upload audio buffer to the call recording
     * @param roomId Room ID
     * @param formParams FromData parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidCallBlob(roomId: string, formParams: PostRoomRoomidCallBlobFormParameters): RequestParams<void, PostRoomRoomidCallBlobErrorHandler> {
        const formData = new FormData();

        formParams.file !== undefined && formData.append("file", formParams.file);

        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/call/blob`),
            form: formData,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(400, "*", handler.badRequest)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }
}

/**
 * Error handler for GetRoomRoomidCall
 */
export type GetRoomRoomidCallErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Header parameters for GetRoomRoomidCall
 */
export interface GetRoomRoomidCallHeaderParameters {
    /**
     * Version ID (when joining with code)
     */
    "x-room-version-id"?: string;

    /**
     * Version Code (when joining with code)
     */
    "x-room-version-code"?: string;
}

/**
 * Error handler for GetRoomRoomidCallConfig
 */
export type GetRoomRoomidCallConfigErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidCallConfig
 */
export type PostRoomRoomidCallConfigErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidCallMute
 */
export type PostRoomRoomidCallMuteErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidCallUnmute
 */
export type PostRoomRoomidCallUnmuteErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidCallTurn
 */
export type PostRoomRoomidCallTurnErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidCallKick
 */
export type PostRoomRoomidCallKickErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetRoomRoomidCallVideos
 */
export type GetRoomRoomidCallVideosErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Query parameters for GetRoomRoomidCallVideos
 */
export interface GetRoomRoomidCallVideosQueryParameters {
    /**
     * ID of the oldest streaming video the client has (for next pages)
     */
    last?: string;
}

/**
 * Header parameters for GetRoomRoomidCallVideos
 */
export interface GetRoomRoomidCallVideosHeaderParameters {
    /**
     * Version ID (when joining with code)
     */
    "x-room-version-id"?: string;

    /**
     * Version Code (when joining with code)
     */
    "x-room-version-code"?: string;
}

/**
 * Error handler for PostRoomRoomidCallVideos
 */
export type PostRoomRoomidCallVideosErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetRoomRoomidCallVideosVideoid
 */
export type GetRoomRoomidCallVideosVideoidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Header parameters for GetRoomRoomidCallVideosVideoid
 */
export interface GetRoomRoomidCallVideosVideoidHeaderParameters {
    /**
     * Version ID (when joining with code)
     */
    "x-room-version-id"?: string;

    /**
     * Version Code (when joining with code)
     */
    "x-room-version-code"?: string;
}

/**
 * Error handler for PostRoomRoomidCallVideosVideoid
 */
export type PostRoomRoomidCallVideosVideoidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for DeleteRoomRoomidCallVideosVideoid
 */
export type DeleteRoomRoomidCallVideosVideoidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidCallVideosVideoidRequestencode
 */
export type PostRoomRoomidCallVideosVideoidRequestencodeErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetRoomRoomidCallVideosVideoidResolution
 */
export type GetRoomRoomidCallVideosVideoidResolutionErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Header parameters for GetRoomRoomidCallVideosVideoidResolution
 */
export interface GetRoomRoomidCallVideosVideoidResolutionHeaderParameters {
    /**
     * Version ID (when joining with code)
     */
    "x-room-version-id"?: string;

    /**
     * Version Code (when joining with code)
     */
    "x-room-version-code"?: string;
}

/**
 * Error handler for GetRoomRoomidCallStatsTop
 */
export type GetRoomRoomidCallStatsTopErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Query parameters for GetRoomRoomidCallStatsTop
 */
export interface GetRoomRoomidCallStatsTopQueryParameters {
    /**
     * Chosen timestamp, set 0 by default to take stats from now
     */
    from?: number;
}

/**
 * Error handler for GetRoomRoomidCallStatsUserUserid
 */
export type GetRoomRoomidCallStatsUserUseridErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Query parameters for GetRoomRoomidCallStatsUserUserid
 */
export interface GetRoomRoomidCallStatsUserUseridQueryParameters {
    /**
     * Chosen timestamp, set 0 by default to take stats from now
     */
    from?: number;

    /**
     * Interval: "1h" | "6h" | "12h" | "1d" | "3d" | "1w" | "1M" | "3M" | "6M" | "1y" | "all"
     */
    interval?: string;
}

/**
 * Error handler for GetRoomRoomidCallRecordingStatus
 */
export type GetRoomRoomidCallRecordingStatusErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Header parameters for GetRoomRoomidCallRecordingStatus
 */
export interface GetRoomRoomidCallRecordingStatusHeaderParameters {
    /**
     * Version ID (when joining with code)
     */
    "x-room-version-id"?: string;

    /**
     * Version Code (when joining with code)
     */
    "x-room-version-code"?: string;
}

/**
 * Error handler for GetRoomRoomidCallPrivate
 */
export type GetRoomRoomidCallPrivateErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Query parameters for GetRoomRoomidCallPrivate
 */
export interface GetRoomRoomidCallPrivateQueryParameters {
    /**
     * ID of the oldest call the client has (for next pages)
     */
    last?: string;
}

/**
 * Error handler for GetRoomRoomidCallPrivateCallid
 */
export type GetRoomRoomidCallPrivateCallidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for DeleteRoomRoomidCallPrivateCallid
 */
export type DeleteRoomRoomidCallPrivateCallidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidCallAudio
 */
export type PostRoomRoomidCallAudioErrorHandler = CommonErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Form parameters for PostRoomRoomidCallAudio
 */
export interface PostRoomRoomidCallAudioFormParameters {
    /**
     * File to upload
     */
    file?: Blob;

    /**
     * Timestamps
     */
    date?: string;

    /**
     * User ID
     */
    uid?: string;
}

/**
 * Error handler for PostRoomRoomidCallAudioSummary
 */
export type PostRoomRoomidCallAudioSummaryErrorHandler = CommonErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetRoomRoomidCallLeavetime
 */
export type GetRoomRoomidCallLeavetimeErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidCallBlob
 */
export type PostRoomRoomidCallBlobErrorHandler = CommonErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Form parameters for PostRoomRoomidCallBlob
 */
export interface PostRoomRoomidCallBlobFormParameters {
    /**
     * File to upload
     */
    file?: Blob;
}

