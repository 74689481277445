<template>
    <ModalDialogContainer ref="modalContainer" v-model:display="displayStatus">
        <div class="modal-dialog modal-md" role="document" @click="stopPropagationEvent">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">{{ $t("Private call code") }}</div>
                    <button type="button" class="btn-close" :title="$t('Close')" @click="close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label v-if="readonly">{{ $t("Use this code to invite more people to the private call") }}:</label>
                        <label v-if="!readonly">{{ $t("Type a code to join a private call or start a new one") }}:</label>
                        <div class="input-group">
                            <input maxlength="80" type="text" autocomplete="off" :readonly="readonly" v-model="code" class="form-control text-center">
                            <button
                                @click="copyAddressUtil(code, $t('Copied to clipboard!'))"
                                class="btn btn-secondary">
                                <i class="fa-regular fa-copy"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="!readonly" type="button" class="btn btn-cancel" @click="close">{{ $t("Cancel") }}</button>
                    <button v-if="!readonly" type="button" @click="randomize" class="btn btn-primary">{{ $t("Generate random") }}</button>
                    <button v-if="readonly" type="button" class="btn btn-secondary" @click="close">{{ $t("Close") }}</button>
                    <button v-if="!readonly" :disabled="!code" @click="confirm" class="btn btn-primary">{{ $t("Accept") }}</button>
                </div>
            </div>
        </div>
    </ModalDialogContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "../../utils/v-model";
import { copyAddressUtil } from '@/utils/copy-address';

import { CallsController } from '@/control/call';

export default defineComponent({
    name: "RequestCallCodeModal",
    emits: ["update:display", "confirm"],
    props: {
        display: Boolean,
        readOnly: Boolean,
        oldCode: String,
    },
    setup(props) {
        return {
            displayStatus: useVModel(props, "display"),
        };
    },
    data: function () {
        return {
            code: "",
            readonly: false,
        };
    },
    methods: {
        close: function () {
            this.displayStatus = false;
        },

        copyAddressUtil,

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.close();
            }
        },

        randomize: function () {
            this.code = CallsController.createRandomCode();
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        confirm: function () {
            this.$emit("confirm", this.code);
            this.close();
        },

    },
    mounted: function () {
        this.readonly = this.readOnly;
        this.code = this.oldCode;
        if (this.display) {
            this.$autoFocus();
        }
    },
    watch: {
        display: function () {
            if (this.display) {
                this.$autoFocus();
            }
        },
    },
});
</script>

<style></style>
