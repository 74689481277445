<template>
    <ModalDialogContainer ref="modalContainer" v-model:display="displayStatus" @keydown="escapeToClose">
        <div class="modal-dialog modal-md" role="document" @click="stopPropagationEvent">
            <div class="modal-content" :class="{ 'bg-white': !isDarkTheme, 'bg-dark': isDarkTheme}">
                <div class="modal-header">
                    <div class="modal-title">{{ $t("Select your language") }}</div>
                    <button type="button" class="btn-close" :title="$t('Close')" @click="close"></button>
                </div>
                <div class="modal-body">
                    <div class="pb-3">
                        <label class="form-label">{{ $t("Select your language") }}:</label>
                        <select class="form-control form-select" v-model="lang" @change="changeLocale">
                            <option v-for="l in languages" :key="l.id" :value="l.id">{{ l.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="close"><i class="fas fa-check"></i> {{ $t("Done") }}</button>
                </div>
            </div>
        </div>
    </ModalDialogContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "@/utils/v-model";
import { AVAILABLE_LANGUAGES } from "@/i18n";
import { getLanguage, setLanguage } from "@/i18n";
import { AppPreferences } from "@/control/app-preferences";

export default defineComponent({
    name: "ChangeLanguageModal",
    emits: ["update:display"],
    props: {
        display: Boolean,
    },
    setup(props) {
        return {
            displayStatus: useVModel(props, "display"),
        };
    },
    data: function () {
        return {
            languages: AVAILABLE_LANGUAGES.map((l) => {
                return { id: l.id, name: l.name };
            }),
            lang: getLanguage(),
            isDarkTheme: AppPreferences.Theme === "dark",
        };
    },
    methods: {
        close: function () {
            this.displayStatus = false;
        },

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.close();
            }
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        changeLocale: function () {
            setLanguage(this.lang);
        },

        onThemeChanged: function () {
            this.isDarkTheme = AppPreferences.Theme === "dark";
        },
    },
    mounted: function () {
        this.$listenOnAppEvent("theme-changed", this.onThemeChanged.bind(this));
        this.onThemeChanged();
        
        if (this.display) {
            this.$autoFocus();
        }
    },
    watch: {
        display: function () {
            if (this.display) {
                this.$autoFocus();
            }
        },
    },
});
</script>

<style scoped>
</style>
