<template>
    <div class="player" tabindex="0" :class="{'player-min': minPlayer, 'player-fullscreen': fullScreen, 'no-controls': !showControls}" @dblclick="toggleFullScreen()" @click="clickPlayer" @mousemove="playerMouseMove" @mouseleave="mouseLeavePlayer" @mouseup="playerMouseUp" @touchmove="playerMouseMove" @touchend="playerMouseUp" @keydown="onPlayerKeyPress" @contextmenu="showPlayerContext">

        <video v-if="!safari" key="defaultplayer" class="player-video" playsinline :muted="muted" @ended="onEnded" @timeupdate="onVideoTimeUpdate" @canplay="onCanPlay" @loadedmetadata="onLoadMetaData" @waiting="onWaitForBuffer(true)" @playing="onWaitForBuffer(false)"></video>
        <video v-else key="safariplayer" :src="staticURL" crossorigin="anonymous" class="player-video safari-video" playsinline webkit-playsinline x-webkit-airplay="allow" :muted="muted" @ended="onEnded" @timeupdate="onVideoTimeUpdate" @canplay="onCanPlay" @loadedmetadata="onLoadMetaData" @waiting="onWaitForBuffer(true)" @playing="onWaitForBuffer(false)" ></video>

        <div v-if="thumbnail && unsupported" class="player-internal-thumbnail-container" :class="{'thumb-vertical': thumbnailvertical}">
            <img :src="thumbnail" alt="thumbnail" />
        </div>

        <div class="player-feeback-container">
            <div class="player-feedback player-feedback-play" key="play" v-if="feedback === 'play'" @animationend="onFeedBackAnimationEnd"><div><i class="fas fa-play"></i></div></div>
            <div class="player-feedback player-feedback-pause" key="pause" v-else-if="feedback === 'pause'" @animationend="onFeedBackAnimationEnd"><div><i class="fas fa-pause"></i></div></div>
        </div>

        <div class="player-loader" v-if="loading && !unsupported">
            <div class="player-lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>

        <div class="player-loader" v-if="unsupported">
            <div v-if="!errmsg" class="player-error">{{ $t("Error: Your browser does not support any available format") }}</div>
            <div v-if="errmsg" class="player-error">{{errmsg}}</div>
        </div>

        <div class="player-title-bar" :class="{'hidden': !showControls}" v-if="title"><span v-if="live"><i class="fas fa-circle live-point me-2"></i>{{ $t("LIVE") }} {{title ? " -" : ""}}</span><span v-if="!live"><i class="fas fa-video me-2"></i> {{ $t("VIDEO") }} {{title ? " -" : ""}}</span> {{title}}</div>
        <div class="player-closeable-btn" :class="{'hidden': !showControls}" v-if="closeable && !fullScreen" @click="onPlayerCloseable" :title="$t('Close')"><i class="fas fa-times"></i></div>

        <div class="player-controls" :class="{'hidden': !showControls}" @click="onClickControls" @dblclick="onClickControls" @mouseenter="enterControls" @mouseleave="leaveControls">
            <div class="player-controls-right" v-if="!verySmallPlayer">
                <a v-if="downloadURL" :href="downloadURL" warning="no" target="_blank" rel="noopener noreferrer"><button type="button" :title="$t('Download')" class="player-btn player-download-btn"><i class="fas fa-download"></i></button></a>
                <button v-if="canmodaldisplay && !fullScreen" type="button" :title="$t('Display as modal')" class="player-btn streaming-modal-show-btn" @click="openInModal()"><i class="fas fa-window-restore"></i></button>
                <button v-if="canmultichange && !fullScreen" type="button" :title="$t('Select this channel')" class="player-btn player-exchange-btn" @click="selectThisChannel()"><i class="fas fa-exchange-alt"></i></button>
                <button type="button" :title="$t('Configuration')" class="player-btn player-config-btn" @click="toggleConfig" @dblclick="onClickConfig"><i class="fas fa-cog"></i></button>
                <button v-if="canexpand && !fullScreen" type="button" :title="renderExpandTitle(expanded)" class="player-btn player-expand-btn" @click="toggleExpand()"><i v-if="!expanded" class="fas fa-expand-arrows-alt"></i><i v-if="expanded" class="fas fa-compress-arrows-alt"></i></button>
                <button type="button" :title="renderFullScreenTitle(fullScreen)" class="player-btn player-expand-btn" @click="toggleFullScreen()"><i v-if="!fullScreen" class="fas fa-expand"></i><i v-if="fullScreen" class="fas fa-compress"></i></button>
            </div>

            <div class="player-controls-left" @mouseleave="hideVolume">
                <button type="button" :title="renderPlayTitle(playing)" class="player-btn player-play-btn" @click="togglePlay"><i v-if="!playing" class="fas fa-play"></i><i v-if="playing" class="fas fa-pause"></i></button>
                <button type="button" :title="renderVolumeTitle(muted, volume)" class="player-btn player-volume-btn" @click="clickOnVolumeIcon" @mouseenter="showVolume">
                    <i v-if="!muted && volume > 0.5" class="fas fa-volume-up"></i>
                    <i v-if="!muted && volume <= 0" class="fas fa-volume-off"></i>
                    <i v-if="!muted && volume > 0 && volume <= 0.5" class="fas fa-volume-down"></i>
                    <i v-if="muted" class="fas fa-volume-mute"></i>
                </button>
                <div class="player-volume-btn-expand" :class="{'hidden': !volumeShown && !volumeGrabbed}" @mousedown="grabVolume" @touchstart="grabVolume">
                    <div class="player-volume-container">
                      <div class="player-volume-bar"></div>
                      <div class="player-volume-current" :style="{width: getVolumeBarWidth()}"></div>
                      <div class="player-volume-thumb" :style="{left: getVolumeThumbLeft()}"></div>
                    </div>
                </div>
                <div class="player-time-label-container" v-if="!live && !minPlayer && !volumeShown">
                    <span>{{renderTime(currentTime)}} / {{renderTime(duration)}}</span>
                </div>
            </div>
        </div>

        <div class="player-timeline" v-if="!live" :class="{'hidden': !showControls}" @mouseenter="enterControls" @mouseleave="mouseLeaveTimeline" @mousemove="mouseMoveTimeline" @dblclick="onClickConfig" @click="onClickConfig" @mousedown="grabTimeline">
            <div class="player-timeline-back"></div>
            <div class="player-timeline-buffer" :style="{width: getTimelineBarWidth(bufferedTime, duration)}"></div>
            <div class="player-timeline-current" :style="{width: getTimelineBarWidth(currentTime, duration)}"></div>
            <div class="player-timeline-thumb" :style="{left: getTimelineThumbLeft(currentTime, duration)}"></div>
        </div>

        <div v-if="tooltipShown" class="player-tooltip" :style="{left: tooltipX + 'px'}"><div v-if="tooltipImage && !tooltipImageInvalid"><img class="player-tooltip-image" :src="tooltipImage" @error="onTooltipImageError" /></div><div class="player-tooltip-text">{{tooltipText}}</div></div>

        <div class="player-config" v-if="displayConfig" @click="onClickConfig" @dblclick="onClickConfig" @mouseenter="enterControls" @mouseleave="leaveControls">
            <div v-if="configPage === 'resolution'">
                <div class="player-config-title">
                    <div class="player-config-back-btn" @click="configGo('home')"><i class="fas fa-chevron-left"></i></div>
                    <div class="player-config-title-text">{{ $t("Video Quality") }}</div>
                </div>
                <div class="player-config-button" @click="onUserClickResolution('best')">
                    <i class="fas fa-check player-config-button-check" :class="{'invisible': !useBestResolution}"></i>
                    <span>{{ $t("Auto")  }}</span>
                </div>
                <div v-for="r in resolutions" :key="r.name" class="player-config-button" @click="onUserClickResolution(r)">
                    <i class="fas fa-check player-config-button-check" :class="{'invisible': useBestResolution || r.name !== resolution}"></i>
                    <span>{{renderResolution(r)}}</span>
                </div>
            </div>
            
            <div v-if="channels && configPage === 'channels'">
                <div class="player-config-title">
                    <div class="player-config-back-btn" @click="configGo('home')"><i class="fas fa-chevron-left"></i></div>
                    <div class="player-config-title-text">{{ $t("Select channel") }}</div>
                </div>
                <div v-for="r in channels" :key="r.id" class="player-config-button" @click="onUserClickChannel(r)">
                    <i class="fas fa-check player-config-button-check" :class="{'invisible': r.id !== channel}"></i>
                    <span>{{r.name}}</span>
                </div>
            </div>

            <div v-if="configPage === 'speed'">
                <div class="player-config-title">
                    <div class="player-config-back-btn" @click="configGo('home')"><i class="fas fa-chevron-left"></i></div>
                    <div class="player-config-title-text">{{ $t("Video Speed") }}</div>
                </div>
                <div v-for="s, index in speeds" :key="index" class="player-config-button" @click="setSpeed(s.value)">
                    <i class="fas fa-check player-config-button-check" :class="{'invisible': s.value !== speed}"></i>
                    <span>{{s.title}}</span>
                </div> 
            </div>

            <div v-if="configPage === 'delay'">
                <div class="player-config-title">
                    <div class="player-config-back-btn" @click="configGo('home')"><i class="fas fa-chevron-left"></i></div>
                    <div class="player-config-title-text">{{ $t("Live delay") }}</div>
                </div>
                <div class="player-config-button" @click="setDelay(30)">
                    <i class="fas fa-check player-config-button-check" :class="{'invisible': 30 !== delay}"></i>
                    <span>30s</span>
                </div>
                <div class="player-config-button" @click="setDelay(20)">
                    <i class="fas fa-check player-config-button-check" :class="{'invisible': 20 !== delay}"></i>
                    <span>20s</span>
                </div>
                <div class="player-config-button" @click="setDelay(10)">
                    <i class="fas fa-check player-config-button-check" :class="{'invisible': 10 !== delay}"></i>
                    <span>10s</span>
                </div>
            </div>

            <div v-if="configPage === 'home'">
                <div v-if="channels && channels.length" class="player-config-button" @click="configGo('channels')">
                    <i class="fas fa-video player-config-button-check"></i>
                    <b>{{ $t("Channel") }}: </b> <span>{{channelname}}</span>
                </div>
                <div class="player-config-button" v-if="!live" @click="toggleLoop">
                    <i class="fas fa-repeat player-config-button-check"></i>
                    <b>{{ $t("Loop") }}: </b> <span v-if="loop">{{ $t("Yes") }}</span><span v-if="!loop">{{ $t("No") }}</span>
                </div>
                <div class="player-config-button" v-if="!live" @click="configGo('speed')">
                    <i class="fas fa-tachometer-alt player-config-button-check"></i>
                    <b>{{ $t("Video Speed") }}: </b> <span>{{renderVideoSpeed(speed)}}</span>
                </div>
                <div v-if="resolutions && resolutions.length" class="player-config-button" @click="configGo('resolution')">
                    <i class="fas fa-image player-config-button-check"></i>
                    <b>{{ $t("Video Quality") }}: </b> <span>{{resolution}}</span><span v-if="useBestResolution"> ({{ $t("Auto") }})</span>
                </div>
                <div v-if="live" class="player-config-button" @click="configGo('delay')">
                    <i class="fas fa-hourglass player-config-button-check"></i>
                    <b>{{ $t("Live delay") }}: </b> <span>{{delay}}s</span>
                </div>
            </div>
        </div>

        <CustomContextMenu ref="contextMenu"></CustomContextMenu>
    </div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from "vue";

import { getVolume, getDelay, getMuted, setVolume, setMuted, setDelay, getBestResolution, getResolution, setChosenResolution } from "@/control/player-preferences";
import { openFullscreen, closeFullscreen } from "@/utils/full-screen";
import CustomContextMenu from "@/components/modals/CustomContextMenu.vue";

import Hls from "hls.js";

export default defineComponent({
    components: {
        CustomContextMenu,
    },
    name: "HLSPlayer",
    props: [
        'title', 
        'thumbnail', 
        'live', 
        'autoplay', 
        'autoloop', 
        'errmsg', 
        'canexpand', 
        'expanded', 
        'inmodal', 
        'channels', 
        'channel', 
        'channelname', 
        'closeable', 
        'canmultichange', 
        'canmodaldisplay'
    ],
    setup: function () {
        return {
            hls: null as Hls,
        };
    },
    data: function () {
        return {
            // Status
            initialized: false,
            playing: false,
            loading: true,
            unsupported: false,
            safari: false,
            staticURL: "",
            downloadURL: "",
            destroyed: false,

            // Layout
            minPlayer: false,
            verySmallPlayer: false,
            fullScreen: false,
            thumbnailvertical: false,

            // Time
            currentTime: 0,
            duration: 0,
            timelineGrabbed: false,
            bufferedTime: 0,
            ended: false,

            // Loop
            loop: false,
            loopChangedByUser: false,

            // Controls
            showControls: true,
            lastControlsInteraction: Date.now(),
            mouseInControls: false,

            // Volume
            volume: getVolume(),
            muted: getMuted(),
            delay: getDelay(),
            volumeShown: this.isTouchDevice(),
            volumeGrabbed: false,

            // Resolution / Quality
            resolutions: [],
            resolution: 'unk',
            useBestResolution: !getResolution(),

            // Speed
            speed: 1,
            speeds: [
                { value: 0.25, title: '0.25' },
                { value: 0.5, title: '0.5' },
                { value: 0.75, title: '0.75' },
                { value: 1, title: this.$t("Normal") },
                { value: 1.25, title: '1.25' },
                { value: 1.5, title: '1.5' },
                { value: 1.75, title: '1.75' },
                { value: 2, title: '2' }
            ],

            // Configuration
            displayConfig: false,
            configPage: 'home',

            // Timeline tooltip
            tooltipShown: false,
            tooltipText: '',
            tooltipX: 0,
            tooltipEventX: 0,
            tooltipImage: '',
            tooltipImageInvalid: false,

            // Thumbnails
            thumbCount: 0,
            thumbURL: '',
            thumbDuration: 5,

            // Stored current time
            lastTimeChangedEvent: 0,

            // Feedback
            feedback: "",  

            interval: null,  
        };
    },
    methods: {
        /* Common */

        getVideoElement: function () {
            if (!this.$el) {
                return null;
            }
            return this.$el.querySelector(".player-video");
        },

        play: function () {
            if (this.unsupported) {
                return;
            }
            this.playing = true;
            if (this.ended) {
                this.setTime(0, true);
            }
            this.getVideoElement().play();
        },

        pause: function () {
            if (this.unsupported) {
                return;
            }
            this.playing = false;
            this.getVideoElement().pause();
            this.interactWithControls();

            this.$emit("time-change", this.currentTime);
            this.lastTimeChangedEvent = Date.now();
        },

        getDuration: function () {
            return this.duration;
        },

        /* Player events */

        onLoadMetaData: function () {
            this.duration = this.getVideoElement().duration;
            this.getVideoElement().volume = this.volume;

            if (!this.live) {
                this.getVideoElement().currentTime = this.currentTime;
                this.getVideoElement().playbackRate = this.speed;
            }
        },

        onVideoTimeUpdate: function () {
            if (this.loading) return;
            this.currentTime = this.getVideoElement().currentTime;
            this.duration = this.getVideoElement().duration;
            if (Date.now() - this.lastTimeChangedEvent > 5000) {
                this.$emit("time-change", this.currentTime);
                this.lastTimeChangedEvent = Date.now();
            }
            if (this.live) {
                const delay = this.duration - this.currentTime;
                if (!isNaN(delay) && delay > this.delay) {
                    this.setTime(this.duration - delay + 1);
                }
            }
        },

        onCanPlay: function () {
            this.loading = false;
            this.$emit("can-play");
            if (!this.playing) {
                return;
            }
            const promise = this.getVideoElement().play();
            if (promise) {
                promise.catch(() => {
                    this.playing = false;
                });
            }
        },

        onWaitForBuffer: function (b) {
            this.loading = b;
            if (b) {
                this.$emit("stall");
                if (this.getVideoElement().duration - this.getVideoElement().currentTime < 1) {
                    this.onEnded();
                } else {
                    if (this.hls) {
                        this.hls.startLoad();
                    }
                }
            }
        },

        onEnded: function () {
            if (this.live) {
                this.$emit("ended");
                return;
            }
            this.loading = false;
            if (this.loop) {
                this.getVideoElement().currentTime = 0;
            } else {
                this.pause();
                this.$emit("ended");
                this.ended = true;
            }
        },

        playerMouseUp: function (e) {
            if (this.volumeGrabbed) {
                if (e.touches && e.touches.length > 0) {
                    this.modifyVolumeByMouse(e.touches[0].pageX, e.touches[0].pageY);
                } else {
                    this.modifyVolumeByMouse(e.pageX, e.pageY);
                }
                this.volumeGrabbed = false;
            }
            if (this.timelineGrabbed) {
                this.onTimelineSkip(e.pageX);
                this.timelineGrabbed = false;
                this.$emit("time-change", this.currentTime);
                this.lastTimeChangedEvent = Date.now();
            }
        },

        playerMouseMove: function (e) {
            this.interactWithControls();

            if (this.volumeGrabbed) {
                if (e.touches && e.touches.length > 0) {
                    this.modifyVolumeByMouse(e.touches[0].pageX, e.touches[0].pageY);
                } else {
                    this.modifyVolumeByMouse(e.pageX, e.pageY);
                }
            }
            if (this.timelineGrabbed) {
                this.onTimelineSkip(e.pageX);
            }
        },

        mouseLeavePlayer: function () {
            if (!this.playing) return;
            this.showControls = false;
            this.volumeShown = this.isTouchDevice();
            this.displayConfig = false;
        },

        /* Source (HLS) */

        setUnsupported: function () {
            if (this.hls) {
                this.hls.destroy();
                this.hls = null;
            }
            const video = this.getVideoElement();
            if (!video) {
                return;
            }
            this.loading = false;
            this.unsupported = true;
            this.playing = false;
            video.pause();
            video.src = null;
            this.resolutions = []
        },

        setSource: function (url: string, downloadURL: string) {
            if (this.destroyed) {
                return;
            }

            this.downloadURL = downloadURL || "";
            this.bufferedTime = 0;
            if (this.hls) {
                this.hls.destroy();
                this.hls = null;
            }
            const video = this.getVideoElement();
            if (!video) {
                return;
            }
            this.loading = true;
            if (Hls.isSupported()) {
                // Most browsers support Media Source Extensions
                // Thanks to this native API we can play any media
                const hls = new Hls({});
                this.hls = hls;
                hls.loadSource(url);
                hls.attachMedia(video);
                hls.on(Hls.Events.ERROR, (event, data) => {
                    if (data.fatal) {
                        switch (data.type) {
                            case Hls.ErrorTypes.NETWORK_ERROR:
                                // try to recover network error
                                console.log('fatal network error encountered, try to recover');
                                hls.startLoad();
                                break;
                            case Hls.ErrorTypes.MEDIA_ERROR:
                                console.log('fatal media error encountered, try to recover');
                                hls.recoverMediaError();
                                break;
                            default:
                                // cannot recover
                                hls.destroy();
                                this.$emit("media-error");
                                break;
                        }
                    }
                });
            } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                // Browsers like safari for IOS does not support MSE
                // But Safari can play HLS using its native codec
                // We set a flag to use the native player
                this.staticURL = url;
                this.safari = true;
            } else {
                // Some old browsers are uncappable of playing the video
                this.loading = false;
                this.unsupported = true;
                this.playing = false;
                video.pause();
                video.src = null;
            }
        },

        /* Resolutions */

        setResolution: function (res) {
            this.resolution = res;
            this.$emit("resolution", res, this.setSource.bind(this));
        },

        setResolutions: function (resolutions, currentTime) {
            this.loading = true;
            this.currentTime = currentTime || 0;
            this.ended = false;
            this.unsupported = false;
            this.duration = 0;
            if (!this.initialized) {
                this.initialized = false;
                if (this.autoplay) {
                    this.playing = true;
                }
            }

            this.resolutions = resolutions.slice().sort(function (a, b) {
                const aR = a.height * a.width * a.fps;
                const bR = b.height * b.width * b.fps;
                if (aR > bR) {
                    return -1;
                } else if (bR > aR) {
                    return 1;
                } else {
                    if (a.height > b.height) {
                        return -1;
                    } else {
                        return 1;
                    }
                }
            });

            this.setResolution(getBestResolution(this.resolutions));
        },

        renderResolution: function (res) {
            return (res.name + "") + " (" + res.width + " x " + res.height + ", " + res.fps + "fps)";
        },

        onUserClickResolution: function (res) {
            if (res === "best" && this.resolutions.length > 0) {
                this.setResolution(this.resolutions[0].name);
                this.useBestResolution = true;
            } else {
                this.setResolution(res.name);
                this.useBestResolution = false;
            }

            setChosenResolution(res); // Save user preferences
        },

        onUserClickChannel: function (channel) {
            this.$emit("channel-switch", channel.id, channel.name);
        },

        /* Time */

        setTime: function (time: number, save?: boolean) {
            time = Math.max(0, time);
            time = Math.min(time, this.duration);
            this.currentTime = time;
            this.getVideoElement().currentTime = time;
            if (save) {
                this.$emit("time-change", this.currentTime);
                this.lastTimeChangedEvent = Date.now();
            }
            if (time < this.duration) {
                this.ended = false;
            }
        },

        /* Speed */

        setSpeed: function (spe) {
            this.speed = spe;
            this.getVideoElement().playbackRate = spe;
        },

        renderVideoSpeed: function (s) {
            for (let i = 0; i < this.speeds.length; i++) {
                if (s === this.speeds[i].value) {
                    return this.speeds[i].title;
                }
            }
            return "" + s;
        },

        /* Configuration */

        configGo: function (page) {
            this.configPage = page;
        },

        toggleConfig: function (e?) {
            if (e) {
                e.stopPropagation();
            }
            this.configPage = "home";
            this.displayConfig = !this.displayConfig;
        },

        onClickConfig: function (e) {
            e.stopPropagation();
        },

        toggleLoop: function () {
            this.loop = !this.loop;
            this.loopChangedByUser = true;
        },

        /* Full screen */

        toggleFullScreen: function () {
            this.fullScreen = !this.fullScreen;
            if (this.fullScreen) {
                openFullscreen(this.$el);
            } else {
                closeFullscreen();
            }
        },

        onExitFullScreen: function () {
            if (!document.fullscreenElement) {
                this.fullScreen = false;
            }
        },

        /* Sound */

        toggleMuted: function () {
            this.muted = !this.muted;
            setMuted(this.muted);
        },

        clickOnVolumeIcon: function () {
            if (!this.volumeShown) {
                this.volumeShown = true;
            } else {
                this.toggleMuted();
            }
        },

        showVolume: function () {
            this.volumeShown = true;
        },

        hideVolume: function () {
            this.volumeShown = this.isTouchDevice();
        },

        getActualVolume: function () {
            if (this.muted) {
                return 0;
            }
            return this.volume;
        },

        changeVolume: function (vol) {
            this.muted = false;
            this.volume = vol;
            setMuted(this.muted);
            setVolume(this.volume);
            this.getVideoElement().volume = this.volume;
        },

        getVolumeBarWidth: function () {
            const volume = Math.min(1, Math.max(0, this.getActualVolume()));
            const width = Math.floor(65 * volume);
            return width + 'px';
        },

        getVolumeThumbLeft: function () {
            const volume = Math.min(1, Math.max(0, this.getActualVolume()));
            const width = Math.floor(65 * volume);
            return Math.floor(width) + 'px';
        },

        modifyVolumeByMouse: function (x, y) {
            if (typeof x !== "number" || typeof y !== "number" || isNaN(x) || isNaN(y)) {
                return;
            }
            const offset = this.$el.querySelector(".player-volume-bar").getBoundingClientRect().left;
            if (x < offset) {
                this.changeVolume(0);
            } else {
                const p = x - offset;
                const vol = Math.min(1, p / 65);
                this.changeVolume(vol);
            }
        },

        grabVolume: function (e) {
            this.volumeGrabbed = true;
            if (e.touches && e.touches.length > 0) {
                this.modifyVolumeByMouse(e.touches[0].pageX, e.touches[0].pageY);
            } else {
                this.modifyVolumeByMouse(e.pageX, e.pageY);
            }
        },

        /* Timeline */

        grabTimeline: function (event) {
            this.timelineGrabbed = true;
            this.onTimelineSkip(event.pageX);
        },

        getTimelineBarWidth: function (time, duration) {
            if (duration > 0) {
                return Math.min(((time / duration) * 100), 100) + "%";
            } else {
                return "0";
            }
        },

        getTimelineThumbLeft: function (time, duration) {
            if (duration > 0) {
                return "calc(" + Math.min((time / duration) * 100, 100) + "% - 7px)";
            } else {
                return "-7px";
            }
        },

        onTimelineSkip: function (x: number) {
            const offset = this.$el.querySelector(".player-timeline-back").getBoundingClientRect().left;
            const width = this.$el.querySelector(".player-timeline-back").offsetWidth || 1;

            if (x < offset) {
                this.setTime(0);
            } else {
                const p = x - offset;
                const tP = Math.min(1, p / width);
                this.setTime(tP * this.duration);
            }
        },

        mouseLeaveTimeline: function () {
            this.tooltipShown = false;
            this.leaveControls();
        },

        mouseMoveTimeline: function (event) {
            const x = event.pageX;
            const offset = this.$el.querySelector(".player-timeline-back").getBoundingClientRect().left;
            const width = this.$el.querySelector(".player-timeline-back").offsetWidth || 1;
            let time;

            if (x < offset) {
                time = 0;
            } else {
                const p = x - offset;
                const tP = Math.min(1, p / width);
                time = tP * this.duration;
            }

            this.tooltipShown = true;
            this.tooltipText = this.renderTime(time);
            const oldTooltipImage = this.tooltipImage;
            this.tooltipImage = this.getThumbnailForTime(time);
            if (oldTooltipImage !== this.tooltipImage) {
                this.tooltipImageInvalid = false;
            }
            this.tooltipEventX = x;

            nextTick(this.tick.bind(this));
        },

        renderTime: function (s) {
            if (isNaN(s) || !isFinite(s)) {
                s = 0;
            }
            s = Math.floor(s);
            let hours = 0;
            let minutes = 0;
            if (s >= 3600) {
                hours = Math.floor(s / 3600);
                s = s % 3600;
            }
            if (s > 60) {
                minutes = Math.floor(s / 60);
                s = s % 60;
            }

            let r = "";

            if (s > 9) {
                r = "" + s + r;
            } else {
                    r = "0" + s + r;
            }

            if (minutes > 9) {
                r = "" + minutes + ":" + r;
            } else {
                r = "0" + minutes + ":" + r;
            }

            if (hours > 0) {
                if (hours > 9) {
                    r = "" + hours + ":" + r;
                } else {
                    r = "0" + hours + ":" + r;
                }
            }

            return r;
        },

        /* Player controls */

        interactWithControls: function () {
            this.showControls = true;
            this.lastControlsInteraction = Date.now();
        },

        enterControls: function () {
            this.mouseInControls = true;
        },

        leaveControls: function () {
            this.mouseInControls = false;
        },

        togglePlay: function () {
            if (this.playing) {
                this.pause();
                this.feedback = "pause";
            } else {
                this.play();
                this.feedback = "play"
            }

            this.displayConfig = false;
        },

        renderPlayTitle: function (playing) {
            if (playing) {
                return this.$t("Pause") + " (K)";
            } else {
                return this.$t("Play") + " (K)";
            }
        },

        renderVolumeTitle: function (muted, vol) {
            if (muted) {
                return this.$t("Volume") + ": " + this.$t("Muted");
            } else {
                const pt = Math.floor(vol * 100);
                return this.$t("Volume") + ": " + pt + "%";
            }
        },

        renderFullScreenTitle: function (fs) {
            if (fs) {
                return this.$t("Exit Full Screen") + " (F)";
            } else {
                return this.$t("Full Screen") + " (F)";
            }
        },

        renderExpandTitle: function (fs) {
            if (fs) {
                return this.$t("Normal mode") + " (E)";
            } else {
                return this.$t("Expanded mode") + " (E)";
            }
        },

        onClickControls: function (e) {
            this.displayConfig = false;
            e.stopPropagation();
        },

        /* Tick (repetitive process to check for changes) */

        tick: function () {
            if (!this.$el) return;
            const el = this.$el;
            const w = el.offsetWidth;
            const h = el.offsetHeight;
            if (w < 480) {
                this.minPlayer = true;
            } else {
                this.minPlayer = false;
            }

            if (w < 256) {
                this.verySmallPlayer = true;
            } else {
                this.verySmallPlayer = false;
            }

            if ((w * 0.5625) > h) {
                this.thumbnailvertical = true;
            } else {
                this.thumbnailvertical = false;
            }

            if (this.showControls && !this.mouseInControls && this.playing) {
                if (Date.now() - this.lastControlsInteraction > 2000) {
                    this.showControls = false;
                    this.volumeShown = this.isTouchDevice();
                    this.displayConfig = false;
                }
            }

            if (this.getVideoElement().buffered.length > 0) {
                this.bufferedTime = this.getVideoElement().buffered.end(this.getVideoElement().buffered.length - 1);
            } else {
                this.bufferedTime = 0;
            }

            if (this.tooltipShown) {
                const tooltip = this.$el.querySelector(".player-tooltip");
                if (tooltip) {
                    let x = this.tooltipEventX;
                    const toolTipWidth = tooltip.offsetWidth;
                    const leftPlayer = this.$el.getBoundingClientRect().left;
                    const widthPlayer = this.$el.offsetWidth;

                    x = x - Math.floor(toolTipWidth / 2);
                    if (x + toolTipWidth > leftPlayer + widthPlayer - 20) {
                        x = leftPlayer + widthPlayer - 20 - toolTipWidth;
                    }
                    if (x < leftPlayer + 10) {
                        x = leftPlayer + 10;
                    }
                    this.tooltipX = x - leftPlayer;
                }
            }
        },

        /* Thumbnails */

        getThumbnailForTime: function (time) {
            if (this.thumbCount <= 0 || !this.thumbURL || this.thumbDuration <= 0) {
                return "";
            }
            let part = Math.floor(time / this.thumbDuration) + 1;
            if (part > this.thumbCount) {
                part = this.thumbCount;
            }

            return this.thumbURL.replace('thumb_%d', 'thumb_' + part);
        },

        setThumbnails: function (meta) {
            this.thumbCount = meta.count;
            this.thumbURL = meta.template_url;
            this.thumbDuration = meta.interval_seconds;
        },

        onTooltipImageError: function () {
            this.tooltipImageInvalid = true;
        },

        /* Keyboard shortcuts */

        onPlayerKeyPress: function (event) {
            this.onKeyPress(event);
        },

        setFocus: function () {
            this.$el.focus();
        },

        isTouchDevice: function() {
            return (('ontouchstart' in window) ||
                (navigator.maxTouchPoints > 0));
        },

        onKeyPress: function (event) {
            let catched = true;

            switch (event.key) {
                case 'M':
                case 'm':
                    this.toggleMuted();
                    this.showVolume();
                    break;
                case 'E':
                case 'e':
                    this.toggleExpand();
                    break;
                case ' ':
                case 'K':
                case 'k':
                    this.togglePlay();
                    break;
                case 'ArrowUp':
                    this.changeVolume(Math.min(1, this.volume + 0.05));
                    this.showVolume();
                    break;
                case 'ArrowDown':
                    this.changeVolume(Math.max(0, this.volume - 0.05));
                    this.showVolume();
                    break;
                case 'F':
                case 'f':
                    this.toggleFullScreen();
                    break;
                case 'J':
                case 'j':
                case 'ArrowRight':
                    if (!this.live) {
                        this.setTime(this.currentTime + 5, true);
                    }
                    break;
                case 'L':
                case 'l':
                case 'ArrowLeft':
                    if (!this.live) {
                        this.setTime(this.currentTime - 5, true);
                    }
                    break;
                case '.':
                    if (!this.playing && !this.live) {
                        this.setTime(this.currentTime - (1 / 30));
                    }
                    break;
                case ',':
                    if (!this.playing && !this.live) {
                        this.setTime(this.currentTime + (1 / 30));
                    }
                    break;
                case 'Home':
                    if (!this.live) {
                        this.setTime(0, true);
                    }
                    break;
                case 'End':
                    if (!this.live) {
                        this.setTime(this.duration, true);
                    }
                    break;
                default:
                    catched = false;
            }

            if (catched) {
                event.preventDefault();
                event.stopPropagation();
                this.interactWithControls();
            }
        },

        toggleExpand: function () {
            if (this.canexpand) {
                this.$emit("toggle-expand");
            }
        },

        onPlayerCloseable: function (event) {
            event.stopPropagation();
            this.$emit("player-close");
        },

        setDelay: function (d) {
            this.delay = d;
            setDelay(d);
        },

        clickPlayer: function () {
            if (!this.live) {
                this.togglePlay();
            }
        },

        selectThisChannel: function () {
            this.$emit("channel-this-select");
        },

        openInModal: function () {
            this.$emit("modal-open");
        },

        onFeedBackAnimationEnd: function () {
            this.feedback = "";
        },

        showPlayerContext: function (event) {
            const options = [];

            if (!this.live) {
                options.push({
                    id: "loop",
                    caption: this.loop ? this.$t("Disable video loop") : this.$t("Enable video loop"),
                    icon: "fas fa-repeat",
                    separateDown: false,
                    callback: () => {
                        this.toggleLoop();
                    },
                });
            }

            if (this.canmodaldisplay) {
                options.push({
                    id: "modal",
                    caption: this.$t("Display as modal"),
                    icon: "fas fa-window-restore",
                    separateDown: false,
                    callback: () => {
                        this.openInModal();
                    },
                 });
            }

            if (this.canmultichange) {
                options.push({
                    id: "multichange",
                    caption: this.$t("Select this channel"),
                    icon: "fas fa-exchange-alt",
                    separateDown: false,
                    callback: () => {
                        this.selectThisChannel();
                    },
                });
            }

            if (this.canexpand) {
                options.push({
                    id: "expand",
                    caption: this.renderExpandTitle(this.expanded),
                    icon: this.expanded ? "fas fa-compress-arrows-alt" : "fas fa-expand-arrows-alt",
                    separateDown: false,
                    callback: () => {
                        this.toggleExpand();
                    },
                });
            }

            options.push({
                id: "config",
                caption: this.$t("Configuration"),
                icon: "fas fa-cog",
                separateDown: false,
                callback: () => {
                    this.toggleConfig();
                },
            });

            if (this.downloadURL) {
                options.push({
                    id: "download",
                    caption: this.$t("Download"),
                    icon: "fas fa-download",
                    separateDown: false,
                    callback: () => {
                        const link = document.createElement("a");
                        link.target = "_blank";
                        link.rel = "noopener noreferrer";
                        link.href = this.downloadURL;
                        link.click();
                    },
                });
            }

            options.push({
                id: "fullscreen",
                caption: this.renderFullScreenTitle(this.fullScreen),
                icon: this.fullScreen ? "fas fa-compress" : "fas fa-expand",
                separateDown: false,
                callback: () => {
                    this.toggleFullScreen();
                },
            });

            event.preventDefault();
            event.stopPropagation();
            
            this.$refs.contextMenu.show(event.pageY + 10, event.pageX + 10, options);
        },
    },
    mounted: function () {
        if (this.errmsg) {
            this.unsupported = true;
        }

        this.loop = !!this.autoloop;


        this.$listenOnDocumentEvent('fullscreenchange', this.onExitFullScreen.bind(this));

        this.interval = setInterval(this.tick.bind(this), 100);
    },
    beforeUnmount: function () {
        this.destroyed = true;
        if (this.hls) {
            this.hls.destroy();
            this.hls = null;
        }
        clearInterval(this.interval);
    },
    watch: {
        errmsg: function () {
            if (this.errmsg) {
                this.unsupported = true;
                this.setUnsupported();
            } else {
                this.unsupported = false;
            }
        },
        autoloop: function () {
            if (!this.loopChangedByUser) {
                this.loop = !!this.autoloop;
            }
        },
    },
});
</script>

<style>
</style>
